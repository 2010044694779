import { extendTheme } from '@chakra-ui/react'
import { StepsTheme as Steps } from "chakra-ui-steps";
import "@fontsource/prompt"

const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'secondary.800',
      },
      a: {
        color: 'primary.600',
      },
      // input: {
      //   bg: 'white'
      // }
    //   button: {
    //     backgroundColor: 'primary.700',
    //     color: 'white',
    //   },
    },
  },
  fonts: {
    body: 'Prompt, sans-serif;',
    heading: "'Prompt', sans-serif;",
    // mono: 'monospace',
  },
  fontSizes: {
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "22px",
    "2xl": "26px",
    "3xl": "30px",
    "4xl": "38px",
    "5xl": "50px",
    "6xl": "66px",
  },
  colors: {
    primary: {
      50: '##FFE1F6',
      100: '#FFB2B8',
      200: '#e3797b', // from brand
      300: '#FF00CC',
      400: '#FB00C1',
      500: '#6d2367', // from brand
      600: '#6d2367', // from brand
      700: '#C800AC',
      // 800: '#B500A9',
      800: '#6d2367', // from brand
      900: '#9200A2',
    },
    secondary: {
      50: '#f0f0fd',
      100: '#d5d5e0',
      200: '#b9b9c6',
      300: '#9d9daf',
      400: '#818197',
      500: '#67677d', // used often
      600: '#5e65a5', // blue from brand
      700: '#2c3671', // dark blue from brand
      800: '#23232d',
      900: '#0b0b16',
    },
    blue: {
      500: '#5e65a5',
      600: '#5e65a5',
    }
  },
  shadows: {
    outline: 'none',
  },
  components: {
    Checkbox: {
      sizes: {
        xl: {
          control: { w: 7, h: 7 },
          icon: { fontSize: '0.8rem' },
          label: { fontSize: 'lg' },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          bg: 'white'
        }
      },
    },
    Steps,
    Card: {
      variants: {
        'with-shadow': {
          bg: 'red.400',
          boxShadow: '0 0 2px 2px #DD00B0',
        },
        // 4. We can override existing variants
        // solid: (props: StyleFunctionProps) => ({
        //   bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        // }),
        // 5. We can add responsive variants
        // sm: {
        //   bg: 'teal.500',
        //   fontSize: 'md',
        // },
      },
    }
    // Button: {
    //     color: 'primary.900'
    // }
  },
})

// isDev && console.log({ theme })

// export type Theme = typeof theme
export default theme
