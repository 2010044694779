import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  doc,
  onSnapshot,
  // addDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  Heading,
  Divider,
  Box,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Center,
  Card,
  Button,
  Stack,
  HStack,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  useToast,
  useDisclosure,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import moment from 'moment';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';

import { db } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import CreateVehicle from '../components/CreateVehicle';

import SpinnerPage from '../components/SpinnerPage';

function Referrals() {
  const toast = useToast();
  const auth = getAuth();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(true);
  const [partnerData, setPartnerData] = useState();
  // const [listings, setListings] = useState(null);
  // const [users, setUsers] = useState(null);
  const [allData, setAllData] = useState(null);
  const [allFilteredData, setAllFilteredData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { userData } = useContext(DataContext);
  const { partnerUid } = userData;

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
      const partner = doc.data();
      setPartnerData(partner);
    });
    return unsubscribe;
  }, [partnerUid]);

  const sortArray = array => {
    // descending order of postedAt or createdAt
    const sortedArray = array.sort((a, b) => {
      return (
        new Date(b.lastLogin || b.postedAt || b.createdAt) -
        new Date(a.lastLogin || a.postedAt || a.createdAt)
      );
    });
    return sortedArray;
  };

  useEffect(() => {
    const fetchListings = async () => {
      // get all listings and users for the refSlug
      try {
        const myListings = [];
        const myUsers = [];

        if (partnerData) {
          const { refSlug } = partnerData;
          // get all listings, check for refSlug
          const colRef = collection(db, 'listings');
          const docsSnap = await getDocs(colRef);
          if (docsSnap.docs.length > 0) {
            docsSnap.forEach(doc => {
              // if this partner slug is in listing.affiliateRef influencerRef or garageRef, add to array
              const listing = doc.data();

              if (listing?.affiliateRef === refSlug) {
                myListings.push(listing);
              }

              if (listing?.garageRef === refSlug) {
                myListings.push(listing);
              }

              if (listing?.influencerRef === refSlug) {
                myListings.push(listing);
              }
            });
          }
          // get all users, check for refSlug
          const colRef2 = collection(db, 'users');
          const docsSnap2 = await getDocs(colRef2);
          if (docsSnap2.docs.length > 0) {
            docsSnap2.forEach(doc => {
              // if this partner uid is in listing.affiliateRef or listing.garageRef, add to array
              const user = doc.data();

              if (user?.affiliateRef === refSlug) {
                myUsers.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  stageTwoVerified: user.stageTwoVerified,
                  // stageOneVerified: user.stageOneVerified,
                  createdAt: user.createdAt,
                  address: user.address,
                  email: user.email,
                  cell: user.cell,
                  photoURL: user.photoURL,
                  uid: user.uid,
                });
              }

              if (user?.garageRef === refSlug) {
                myUsers.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  stageTwoVerified: user.stageTwoVerified,
                  stageOneVerified: user.stageOneVerified,
                  createdAt: user.createdAt,
                  address: user.address,
                  email: user.email,
                  cell: user.cell,
                  photoURL: user.photoURL,
                  uid: user.uid,
                });
              }

              if (user?.influencerRef === refSlug) {
                myUsers.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  stageTwoVerified: user.stageTwoVerified,
                  // stageOneVerified: user.stageOneVerified,
                  createdAt: user.createdAt,
                  address: user.address,
                  email: user.email,
                  cell: user.cell,
                  photoURL: user.photoURL,
                  uid: user.uid,
                });
              }
            });
          }

          const userToListings = {};

          // Initialize the listings array for each user
          myUsers.forEach(user => {
            userToListings[user.uid] = [];
          });

          // either put each listing in its user's array, or in unmatched
          const unmatchedListings = [];

          myListings.forEach((listing, i) => {
            if (userToListings[listing.sellerUid]) {
              userToListings[listing.sellerUid].push(listing);
            } else {
              unmatchedListings.push(listing);
            }
          });

          // Update users with listings arrays
          const updatedUsers = myUsers.map(user => ({
            ...user,
            listings: sortArray(userToListings[user.uid]),
          }));

          // also get any partners that may have a ref
          const partnersWithRef = [];
          // get all partners, check for refSlug
          const colRef3 = collection(db, 'partners');
          const docsSnap3 = await getDocs(colRef3);
          if (docsSnap3.docs.length > 0) {
            docsSnap3.forEach(doc => {
              // if this partner uid is in listing.affiliateRef or listing.garageRef, add to array
              const user = doc.data();

              if (user?.affiliateRef === refSlug) {
                partnersWithRef.push({
                  businessName: user.businessName,
                  createdAt: user.createdAt,
                  address: user.address,
                  uid: user.uid,
                });
              }

              if (user?.garageRef === refSlug) {
                partnersWithRef.push({
                  businessName: user.businessName,
                  createdAt: user.createdAt,
                  address: user.address,
                  uid: user.uid,
                });
              }

              if (user?.influencerRef === refSlug) {
                partnersWithRef.push({
                  businessName: user.businessName,
                  createdAt: user.createdAt,
                  address: user.address,
                  uid: user.uid,
                });
              }
            });
          }

          const finalMergedArray = [
            ...updatedUsers,
            ...unmatchedListings,
            ...partnersWithRef,
          ];

          const finalSortedMergedArray = sortArray(finalMergedArray);
          setAllData(finalSortedMergedArray);
          setAllFilteredData(finalSortedMergedArray);
          // console.log(finalSortedMergedArray);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast({
          description: 'Listing fetch error',
          status: 'error',
        });
      }
    };

    fetchListings();
  }, [toast, auth, partnerData]);

  const filterArray = (array, filterValue) => {
    const filteredArray = array.filter(
      item =>
        (item?.title &&
          item?.title.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item?.vin &&
          item?.vin.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item?.firstName &&
          item?.firstName.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item?.lastName &&
          item?.lastName.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item?.listings &&
          item?.listings.some(obj =>
            obj.title.toLowerCase().includes(filterValue.toLowerCase())
          )) ||
        (item?.listings &&
          item?.listings.some(obj =>
            obj.sellerName.toLowerCase().includes(filterValue.toLowerCase())
          )) ||
        (item?.listings &&
          item?.listings.some(obj =>
            obj.vin.toLowerCase().includes(filterValue.toLowerCase())
          )) ||
        (item.eezeID &&
          item?.eezeID.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item.businessName &&
          item?.businessName
            .toLowerCase()
            .includes(filterValue.toLowerCase())) ||
        // item.sellerLocation.toLowerCase().includes(filterValue.toLowerCase()) ||
        (item?.sellerName &&
          item?.sellerName.toLowerCase().includes(filterValue.toLowerCase()))
    );
    return sortArray(filteredArray);
  };

  const handleFilter = e => {
    let inputValue = '';
    if (e?.target) {
      inputValue = e.target.value;
    }
    const filteredData = filterArray(allData, inputValue);
    // console.log('vehicleListings', vehicleListings);
    // console.log('filteredArray', filteredVehicles);
    setAllFilteredData(filteredData);

    // const filteredProducts = filterArray(productListings, inputValue);
    // console.log('productListings', productListings);
    // console.log('filteredProducts', filteredProducts);
    // setFilteredProductListings(filteredProducts);
  };

  const handleClear = () => {
    inputRef.current.value = '';
    handleFilter();
    // inputRef.current.click();
    // inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  // const addVehicle = async user => {
  //   // console.log('user')
  //   // console.log(user)
  //   // console.log('partnerData', partnerData)
  //   // const vehicleOnly = true;
  //   // setButtonsIsLoading(true);

  //   try {
  //     const docRef = await addDoc(collection(db, 'listings'), {
  //       createdAt: new Date().toString(),
  //       // affiliateRef: userData?.affiliateRef || '',
  //       garageRef: partnerData?.refSlug,
  //       // influencerRef: userData?.influencerRef || '',
  //       sellerUid: user.uid,
  //       language: 'EN',
  //       sellerName: user.firstName + ' ' + user.lastName.substring(0, 1) + '.',
  //       sellerFullName: user.firstName + ' ' + user.lastName,
  //       sellerEmail: user?.email,
  //       sellerCell: user?.cell,
  //       sellerLocation: user.address
  //         ? user?.address?.city + ', ' + user.address?.province.toUpperCase()
  //         : '',
  //       sellerPhotoURL: user?.photoURL || '',
  //       status: 'PRIVATE', // vehicleOnly ? LISTING.STATUS.PRIVATE : LISTING.STATUS.DRAFT,
  //       type: 'VEHICLE', // LISTING.TYPE.VEHICLE,
  //       province: user.address ? user.address?.province.toLowerCase() : 'on',

  //       year: '',
  //       make: '',
  //       model: '',
  //       style: '',
  //       vin: '',
  //       odometer: '',
  //       // odometerType: user.address?.country === 'ca' ? 'km' : 'km',
  //       odometerType: 'km',
  //       condition: '',
  //       title: '',
  //       subtitle: '',
  //       // currency: user.address?.country === 'ca' ? 'CAD' : 'CAD',
  //       currency: 'CAD',
  //       askingPrice: '',
  //       description: '',
  //       notes: '',
  //       otherMarketplaceURL: '',
  //       potentialBuyers: '',
  //     });

  //     navigate('/edit/' + docRef.id);
  //   } catch (error) {
  //     // setButtonsIsLoading(false);
  //     // console.error(error);
  //     toast({
  //       title: 'Could not create vehicle',
  //       description: isDev ? JSON.stringify(error) : '',
  //       status: 'error',
  //       isClosable: true,
  //     });
  //   }
  // };
  // const addWarranty = listing => {
  //   navigate('/warranty/' + listing.uid);
  // };
  const renderListing = (listing, hideSellerName) => {
    let documentsUploaded;
    if (listing.partnerConditionReport) documentsUploaded = true;
    if (listing.partnerSafetyTest) documentsUploaded = true;
    if (listing.partnerCarfax) documentsUploaded = true;
    if (listing.partnerInvoice) documentsUploaded = true;
    const content = (
      <>
        {!hideSellerName && (
          <>
            <Text as="b">{listing?.sellerFullName}</Text>
            {/* <Text color="grey">
              {listing.sellerLocation}

              {' - '}
              {moment(
                listing.createdAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MM/DD/YYYY')}
            </Text> */}
          </>
        )}
        <Accordion allowToggle w="full">
          <AccordionItem key={listing.uid}>
            <AccordionButton
              fontSize="sm"
              px={0}
              borderLeftWidth={4}
              borderLeftColor={documentsUploaded ? 'white' : 'primary.500'}
              pl={2}
            >
              <Box as="span" flex="1" textAlign="left">
                {listing.title ? (
                  <Text>
                    {listing.title +
                      ' ' +
                      (listing.vin && listing.vin.slice(-6))}
                  </Text>
                ) : (
                  <Text>*Draft Vehicle*</Text>
                )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              pb={4}
              bgColor="gray.50"
              borderLeftWidth={4}
              borderLeftColor={documentsUploaded ? 'white' : 'primary.500'}
            >
              <HStack justify="space-between">
                <Text>Created:</Text>
                <Text>
                  {moment(
                    listing.postedAt || listing.createdAt,
                    'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                  ).format('MM/DD/YYYY')}
                </Text>
              </HStack>
              {/* <HStack justify="space-between">
                <Text>Vehicle Status:</Text>
                {listing.status === 'COMPLETED' ? (
                  <Text>SOLD</Text>
                ) : (
                  <Text>{listing.verified ? 'VERIFIED' : 'UNVERIFIED'}</Text>
                )}
              </HStack> */}

              {listing.postedAt && (
                <HStack justify="space-between">
                  <Text>Transaction:</Text>
                  <Text>
                    {listing.status === 'COMPLETED'
                      ? 'COMPLETED'
                      : ' NOT COMPLETED'}
                  </Text>
                </HStack>
              )}

              {/* {listing.eezePlan && (
                <HStack justify="space-between">
                  <Text>EEZE Plan:</Text>
                  <Text>{listing?.eezePlan?.name.toUpperCase()}</Text>
                </HStack>
              )} */}
              {listing.warrantyName ? (
                <>
                  <HStack justify="space-between">
                    <Text>
                      Warranty:
                      <br />
                    </Text>
                    <Text flex={1} align="right">
                      {listing.warrantyName}
                      <br />
                      {listing.warrantyName.includes('90') &&
                        listing.warrantySafetyPromo && (
                          <>
                            SAFETY TEST PROMOTION
                            <br />
                          </>
                        )}
                      {listing.warrantyActive ? 'ACTIVE' : 'NOT ACTIVE'}
                      <span style={{ color: 'purple', fontStyle: 'italic' }}>
                        {listing.warrantyEndDate &&
                          ' to ' + listing.warrantyEndDate}
                      </span>
                    </Text>
                  </HStack>
                  {/* <Divider my={2} /> */}
                </>
              ) : (
                <>
                  {listing.warrantyAddonCode && (
                    <HStack justify="space-between">
                      <Text>
                        Warranty:
                        <br />
                      </Text>
                      <Text flex={1} align="right">
                        SELECTED - NOT COMPLETED
                      </Text>
                    </HStack>
                  )}
                </>
              )}

              {partnerData.type === 'garage' && (
                <>
                  <Divider my={1} />
                  {documentsUploaded && (
                    <Text as="i" color="grey">
                      Documents uploaded
                    </Text>
                  )}
                  <HStack justify="space-between">
                    <Button
                      size="sm"
                      variant={documentsUploaded ? 'outline' : 'solid'}
                      colorScheme="primary"
                      onClick={() => navigate(`/listing/${listing.uid}`)}
                    >
                      {documentsUploaded ? 'View' : 'Add'} Documents
                    </Button>
                    {!listing.warrantyActive && listing.title && (
                      <Button
                        size="sm"
                        variant="outline"
                        colorScheme="primary"
                        // colorScheme="blue"
                        onClick={() => navigate('/warranty/' + listing.uid)}
                      >
                        Add Warranty
                      </Button>
                    )}
                  </HStack>
                </>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </>
    );
    if (hideSellerName) {
      // content within a user
      return (
        <Box
          key={listing.uid}
          // borderBottomWidth={1}
          // borderLeftWidth={1}
          // borderColor={!hideSellerName ? 'black' : ''}
          p={0}
        >
          {content}
        </Box>
      );
    }
    // content is its own card
    return (
      <Card
        key={listing.uid}
        // borderBottomWidth={1}
        borderWidth={1}
        borderColor="darkgrey"
        // borderLeftWidth={1}
        // borderColor={!hideSellerName ? 'black' : ''}
        p={4}
      >
        {content}
      </Card>
    );
  };
  const renderPartner = partner => {
    return (
      <Card
        key={partner.uid}
        // borderBottomWidth={1}
        // borderColor="black"
        borderWidth={1}
        borderColor="darkgrey"
        // borderColor="primary.500"
        p={4}
      >
        <HStack>
          <Text as="b">{partner.businessName}</Text>
        </HStack>

        {/* <Text color="grey">
          {partner?.address?.city && partner?.address?.city + ' - '}
          {moment(partner.createdAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
            'MM/DD/YYYY'
          )}
        </Text> */}
      </Card>
    );
  };

  const renderUser = user => {
    return (
      <Card
        key={user.uid}
        borderWidth={1}
        borderColor="darkgrey"
        // borderBottomWidth={1}
        // borderColor="black"
        // bg='red'
        // borderColor="primary.500"
        p={4}
        // pb={2}
      >
        <HStack justify="space-between" textAlign="center">
          <Box>
            <Text as="b">
              {/* <b> */}
              {user.firstName} {user.lastName}
              {/* </b> */}
              {/* {!user?.stageTwoVerified && ' (Unverified)'} */}
            </Text>

            {/* <Text color="grey">
              {user?.address?.city && user?.address?.city + ' - '}
              {moment(user.createdAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                'MM/DD/YYYY'
              )}
            </Text> */}
          </Box>
          {partnerData.type === 'garage' && (
            <Box>
              <Button
                size="sm"
                variant="link"
                // colorScheme="primary"
                // color='grey'
                // disabled={!user.stageOneVerified}
                // onClick={() => {
                //   if (
                //     !window.confirm(
                //       'Add new vehicle for this customer, are you sure?'
                //     )
                //   ) {
                //     return;
                //   }
                //   addVehicle(user);
                // }}
                onClick={() => {
                  setSelectedCustomer(user);
                  onOpen();
                }}
              >
                <Text as="i" fontSize="xs">
                  Add Vehicle
                </Text>
              </Button>
              {/* <Text color="white">-</Text> */}
            </Box>
          )}
        </HStack>
        {user.listings && (
          <>
            {/* <Stack spacing={1} mt={1}> */}
            {user.listings.map(listing => renderListing(listing, true))}
            {/* </Stack> */}
          </>
        )}
      </Card>
    );
  };

  const renderAllData = () => {
    if (loading) {
      return <SpinnerPage />;
    }
    if (!allFilteredData) {
      return <SpinnerPage />;
    }

    return (
      <>
        {allFilteredData.length > 0 ? (
          <>
            {allFilteredData.map(item => {
              if (item.firstName) {
                return renderUser(item);
              } else {
                if (item.businessName) {
                  return renderPartner(item);
                } else {
                  return renderListing(item);
                }
              }
            })}
            <br />
            <br />
          </>
        ) : (
          <Center mt={20}>
            <Text>No Results</Text>
          </Center>
        )}
      </>
    );
  };
  // console.log('vehicleListings', vehicleListings);
  // if (!partnerData) {
  //   return <SpinnerPage />;
  // }
  // console.log(allData)
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={5}>
          <CreateVehicle user={selectedCustomer} partnerData={partnerData} />
          <ModalFooter>
            <Button mt={10} w="full" variant="link" size="sm" onClick={onClose}>
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Header back /> */}
      <AppBody>
        {/* <Heading size="lg">Your Customers</Heading> */}
        <Heading size="lg">Referred to EEZE</Heading>
        <Divider borderColor="primary.500" borderWidth={1} />

        <Text>
          Signed up using your link{' '}
          {partnerData?.refSlug && 'eeze.co/' + partnerData?.refSlug}
        </Text>
        {/* <Text>
          Any EEZE customer or vehicle added using your referral link{' '}
          {partnerData?.refSlug && 'eeze.co/' + partnerData?.refSlug}
        </Text> */}
        <InputGroup my={3}>
          <InputLeftElement children={<SearchIcon color="grey" />} />
          <Input
            onChange={handleFilter}
            ref={inputRef}
            placeholder="Filter by Name, Vehicle, VIN or EEZE ID"
            _placeholder={{ fontSize: 'sm', color: 'gray.500' }}
          />
          <InputRightElement>
            {/* <Button variant="ghost" onClick={handleClear}>
              X
            </Button> */}
            <IconButton
              p={5}
              variant="ghost"
              onClick={handleClear}
              size="xs"
              color="grey"
              icon={<CloseIcon />}
            />
          </InputRightElement>
        </InputGroup>
        {/* <Text as="i" align="center" color="grey">
          Search by Name, Vehicle, VIN or EEZE ID.{' '}
        </Text> */}
        {/* <Text as="i" align="center" color="lightgrey">
          Default sorted by last login or date created
        </Text> */}
        {/* <Divider borderColor="primary.500" borderWidth={1} my={2} /> */}
        <Stack
          // mt={2}
          spacing={1}
        >
          {renderAllData()}
        </Stack>
      </AppBody>
    </>
  );
}

export default Referrals;
