import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast, Heading } from '@chakra-ui/react';

import { db } from '../constants';
import SpinnerPage from '../components/SpinnerPage';
import WarrantyPage from '../components/WarrantyPage';
import AppBody from '../components/AppBody';
import Header from '../components/Header';

function Warranty() {
  const [listing, setListing] = useState(false);

  // const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();

  // Fetch listing to edit
  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingUid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const thisListing = docSnap.data();

        setListing({ ...thisListing, uid: params.listingUid });
      } else {
        navigate('/');
        toast({
          title: 'Listing does not exist',
          status: 'error',
          isClosable: true,
        });
      }
    };

    fetchListing();
  }, [params, navigate, toast]);

  if (!listing) {
    return <SpinnerPage />;
  }

  return (
    <>
      <Header back previousScreen />
      <AppBody>
        <Heading size="sm" textAlign="center">
          Warranty
        </Heading>
        <Heading size="md" textAlign="center">
          {listing?.sellerFullName}
        </Heading>
        <Heading size="lg" textAlign="center">
          {listing?.title}
        </Heading>
        <br />
        <br />
        <WarrantyPage listing={listing} />
      </AppBody>
    </>
  );
}

export default Warranty;
