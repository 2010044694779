import {
    getAuth,
} from 'firebase/auth';

import {
    CREATE_MEMBER,
} from '../constants';

const createMember = async (formData) => {
    const auth = getAuth();
    const body = JSON.stringify(formData);
    try {
        const token = await auth.currentUser.getIdToken()
        const config1 = {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body,
        }

        const response = await fetch(
            CREATE_MEMBER, config1
        );

        const data = await response.json();
        // console.log('data from createMember', data);
        return data
    }

    catch (error) {
        // console.log(JSON.stringify(error))
        return {
            success: false,
            error,
            errorJson: JSON.stringify(error)
        };
    }
}

export default createMember;
