import { useState, useEffect } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Box,
  Container,
  Center,
  Select,
  Spinner,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';

import {
  db,
  isDev,
  LISTING,
} from '../constants';
import CARS from '../services/carInfo';
import decodeVIN from '../services/decodeVIN';
import AppBody from '../components/AppBody';
import WarrantyPage from '../components/WarrantyPage';
import SpinnerPage from '../components/SpinnerPage';
import Header from '../components/Header';

function EditListingVehicle(props) {
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [decoding, setDecoding] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [queryParameters] = useSearchParams();
  const [listing, setListing] = useState(props.listing);
  const [formData, setFormData] = useState({
    ...props.listing,
  });
  useEffect(() => {
    // change tab
    if (queryParameters.get('t')) {
      const index = queryParameters.get('t');
      setTabIndex(Number(index));
    }
  }, [queryParameters]);

  useEffect(() => {
    // real time listener in EditListing that feeds props
    // update when changes
    setFormData(props.listing);
    setListing(props.listing);
    setLoading(false);
  }, [props.listing]);

  const handleTabsChange = index => {
    onClickSave(formData);
    if (!index) {
      // if 0, change to 1
      // if 1, change to 0
      if (tabIndex === 0) {
        return setTabIndex(1);
      } else if (tabIndex === 1) {
        return setTabIndex(0);
      }
    }
    setTabIndex(index);
  };

  const onClickSave = newFormData => {
    setIsSavingForm(true); // to disable buttons
    setIsEditing(false); // to prevent new entries

    const formDataToSave = newFormData ? newFormData : formData;
    // console.log('formDataToSave', formDataToSave)
    // console.log('newFormData', newFormData)
    // set form data to firestore

    const docRef = doc(db, 'listings', listing.uid);
    setDoc(docRef, formDataToSave, { merge: true })
      .then(() => {
        // console.log('Document Field has been updated successfully');
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true);
      })
      .catch(error => {
        // console.log(error);
        toast({
          description: 'Listing update error',
          status: 'error',
        });
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true); // back to editing
      });
  };

  const onClickCancel = () => {
    if (listing !== formData) {
      // are you sure
      if (!window.confirm('Discard changes, are you sure?')) {
        return;
      }
    }

    setFormData(listing);
  };

  const onChange = e => {
    let boolean = null;
    let value = e.target.value;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }

    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: boolean ?? value,
    }));
  };

  const onChangeVIN = e => {
    if (e.target.value !== '') {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (!e.target.value.match(regEx)) return;
      if (e.target.value.includes(' ')) return;
      if (e.target.value.includes('I')) return;
      if (e.target.value.includes('i')) return;
      if (e.target.value.includes('O')) return;
      if (e.target.value.includes('o')) return;
      if (e.target.value.includes('Q')) return;
      if (e.target.value.includes('q')) return;
      if (e.target.value.length > 17) return;
    }
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };

  const insertRandomVIN = () => {
    const index = Math.floor(Math.random() * CARS.RANDOM_VINS.length);
    setFormData(prevState => ({
      ...prevState,
      vin: CARS.RANDOM_VINS[index],
    }));
  };

  const onClickDecodeVIN = async (vin, odometer) => {
    setDecoding(true);

    try {
      const decodeResponse = await decodeVIN(vin);
      // console.log('response from decode', decodeResponse);

      if (!decodeResponse.success) {
        toast({
          title:
            'VIN Decode fail, please verify and retry or enter information manually',
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.status === 'NOT_FOUND') {
        toast({
          title:
            'VIN Decode fail, please verify or enter information manually. ' +
            decodeResponse.message,
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.data.years) {
        toast({
          title:
            'VIN Decode fail, please verify and retry or enter information manually',
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      // let title = formData.title;
      // if (!title) {
      const title = `${decodeResponse.data.years[0]?.year} ${decodeResponse.data.make?.name} ${decodeResponse.data.model?.name}`;
      // }
      setFormData(prevState => ({
        ...prevState,
        fullVehicle: decodeResponse.data,
        year: decodeResponse.data.years[0]?.year,
        make: decodeResponse.data.make?.name,
        model: decodeResponse.data.model?.name,
        title,
        vin,
        odometer: odometer ? odometer : '',
        colorExterior: '',
        colorInterior: '',
        style: '',
      }));
      toast({
        title: 'VIN Decoded!',
        status: 'success',
        isClosable: true,
      });
      setDecoding(false);
    } catch (e) {
      //  console.log(e);
      toast({
        title: 'VIN Decode fail, please verify and retry',
        status: 'error',
        isClosable: true,
      });
      setDecoding(false);
    }
  };

  let decodeIsDisabled = true;
  if (formData?.vin.length === 17) {
    decodeIsDisabled = false;
  }

  // let missingItems = [];
  let detailsMissingItems = [];
  let descriptionIsComplete = true;
  // let listingIsComplete = true;
  if (!formData.year) {
    detailsMissingItems.push('Missing Vehicle Year');
    descriptionIsComplete = false;
  }
  if (!formData.make) {
    detailsMissingItems.push('Missing Vehicle Make');
    descriptionIsComplete = false;
  }
  if (!formData.model) {
    detailsMissingItems.push('Missing Vehicle Model');
    descriptionIsComplete = false;
  }
  if (!formData.style) {
    detailsMissingItems.push('Missing Vehicle Style');
    descriptionIsComplete = false;
  }
  if (!formData.odometer) {
    detailsMissingItems.push('Missing Odometer Reading');
    descriptionIsComplete = false;
  }
  if (!formData.title) {
    detailsMissingItems.push('Missing Listing Title');
    descriptionIsComplete = false;
  }

  if (!formData.listingProfilePhoto) {
    detailsMissingItems.push('Missing Profile Photo');
  }
  if (!formData.vehicleOdometerPhoto) {
    detailsMissingItems.push('Missing Dashboard Odometer Photo');
  }
  if (!formData.vehicleLicensePhoto) {
    detailsMissingItems.push('Missing License Photo');
  }
  if (!formData.vehicleVINPhoto) {
    detailsMissingItems.push('Missing VIN Photo');
  }
  if (!formData.vehicleRegistrationPhoto) {
    detailsMissingItems.push('Missing Registration Photo');
  }
  if (!formData.vehicleInsurancePhoto) {
    detailsMissingItems.push('Missing Insurance Photo');
    // photosAreComplete = false;
  }

  // let canPost = true;
  // if (!listingIsComplete) canPost = false;
  // if (!formData.askingPrice) canPost = false;
  // if (!photosAreComplete) canPost = false;

  let editIsDisabled = false;
  if (listing.status === LISTING.STATUS.COMPLETED) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_SIGNED) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_SIGNING) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_FUNDING) editIsDisabled = true;

  const renderDescription = () => {
    if (decoding) {
      return (
        <Box minHeight="80vh">
          <Center m={10} p={10}>
            <Spinner size="xl" color="primary.500" />
          </Center>
        </Box>
      );
    }

    return (
      <Box minHeight="50vh">
        {editIsDisabled && (
          <Center>
            <Text color="primary.500">
              At this stage, you cannot edit this listing
            </Text>
          </Center>
        )}

        {!formData.fullVehicle && (
          <>
            <FormControl mt={5}>
              <Center textAlign="center">Enter vehicle VIN</Center>
              <Stack direction="row" align="center">
                <FormLabel m={0}>
                  VIN
                  {!formData.vin && <span style={{ color: 'red' }}> *</span>}
                </FormLabel>
              </Stack>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChangeVIN}
                disabled={formData.fullVehicle ? true : false}
              />
            </FormControl>

            {isDev && !formData.vin && (
              <Button
                onClick={insertRandomVIN}
                mt={10}
                size="sm"
                variant="link"
                color="lightgray"
              >
                <i>DEV Insert Random VIN</i>
              </Button>
            )}
          </>
        )}

        {formData.vin && !formData.make && !formData.model && (
          <Button
            onClick={() => onClickDecodeVIN(formData.vin)}
            colorScheme="blue"
            w="full"
            mt={2}
            disabled={decodeIsDisabled}
          >
            DECODE
          </Button>
        )}

        {formData.fullVehicle && (
          <>
            {descriptionIsComplete && (
              <>
                <Button
                  onClick={() => {
                    onClickSave(formData);
                    setTabIndex(tabIndex + 1);
                  }}
                  mb={5}
                  size='lg'
                  w="full"
                  colorScheme="primary"
                >
                  Select Warranty Plan
                </Button>
              </>
            )}

            <FormLabel m={0} w="50%">
              VIN
            </FormLabel>
            <InputGroup>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChange}
                disabled
              />
            </InputGroup>

            <FormControl mt="2%">
              <Stack direction="row" w="full">
                <FormLabel m={0} w="50%">
                  Year
                </FormLabel>
                <FormLabel m={0}>Make</FormLabel>
              </Stack>
              <InputGroup>
                <Input
                  id="year"
                  // type="text"
                  value={formData.year}
                  // onChange={onChange}
                  disabled
                />
                <Input
                  id="make"
                  type="text"
                  value={formData.make}
                  onChange={onChange}
                  disabled
                  list="makes-list"
                />
              </InputGroup>
              <FormLabel m={0} w="50%">
                Model
              </FormLabel>
              <InputGroup>
                <Input
                  id="model"
                  type="text"
                  value={formData.model}
                  onChange={onChange}
                  disabled
                />
              </InputGroup>
            </FormControl>

            <FormControl mt="2%">
              <FormLabel m={0}>
                Trim
                {!formData.style && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                {formData.trim && (
                  <Input
                    id="style"
                    placeholder="Or enter manually"
                    type="text"
                    value={formData.trim}
                    onChange={onChange}
                    // disabled
                  />
                )}
                {formData.fullVehicle?.years && (
                  <Select
                    id="style"
                    value={formData.style}
                    onChange={onChange}
                    disabled={
                      !isEditing || editIsDisabled || formData?.verified
                    }
                  >
                    <option value="">Select</option>
                    {formData.fullVehicle.years[0].styles.map((style, i) => (
                      <option key={i} value={style.name}>
                        {style.name}
                      </option>
                    ))}
                  </Select>
                )}
              </InputGroup>
            </FormControl>

            <FormControl mt={'2%'} mb={2}>
              <FormLabel m={0}>
                Odometer
                {!formData.odometer && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                <Input
                  id="odometer"
                  type="number"
                  w="50%"
                  value={formData.odometer}
                  onChange={onChange}
                  disabled={formData?.verified}
                  // onBlur={onBlurOdometer}
                />
                <Input
                  id="odometerType"
                  // type="number"
                  w="50%"
                  value={formData.odometerType}
                  onChange={onChange}
                  disabled
                  // onBlur={onBlurOdometer}
                />
              </InputGroup>
            </FormControl>
          </>
        )}
      </Box>
    );
  };

  if (loading) {
    return <SpinnerPage />;
  }
  // console.log(formData)
  return (
    <>
      <Header back previousScreen />
      <AppBody>
        <Heading size="sm" textAlign="center">
          Add Vehicle
        </Heading>
        <Heading size="md" textAlign="center">
          {formData?.sellerFullName}
        </Heading>
        <Heading size="lg" textAlign="center">
          {formData?.title}
        </Heading>

        <Container maxW={'2xl'} p={0} mt={2}>
          <Tabs
            isFitted
            variant="enclosed-colored"
            onChange={handleTabsChange}
            index={tabIndex}
          >
            <TabList mb="1em">
              <Tab>
                Details
                {!descriptionIsComplete && (
                  <Text ml={1} color="red">
                    *
                  </Text>
                )}
              </Tab>

              <Tab isDisabled={descriptionIsComplete ? false : true}>
                Warranty
              </Tab>
              {/* <Tab isDisabled={descriptionIsComplete ? false : true}>
                CARFAX
              </Tab> */}
            </TabList>

            <TabPanels p={0} m={0}>
              <TabPanel p={0} m={0}>
                {renderDescription()}
                {formData.fullVehicle && (
                  <>
                    {/* {renderPhotos()} */}
                    <ButtonGroup my={4} alignItems="center" w="100%">
                      <Button
                        variant="outline"
                        size={'sm'}
                        onClick={onClickCancel}
                        isLoading={isSavingForm}
                        flex={1}
                      >
                        Cancel
                      </Button>
                      {!descriptionIsComplete ? (
                        <Button
                          colorScheme="primary"
                          variant="solid"
                          flex={1}
                          onClick={() => {
                            onClickSave(formData);
                          }}
                        >
                          SAVE
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            onClickSave(formData);
                            setTabIndex(tabIndex + 1);
                          }}
                          flex={1}
                          variant="solid"
                          colorScheme="primary"
                        >
                          SAVE & NEXT
                        </Button>
                      )}
                    </ButtonGroup>
                  </>
                )}
              </TabPanel>

              <TabPanel p={0} m={0}>
                <Box p={0} m={0} minHeight="70vh">
                  {/* {notAvailableWarning ? notAvailableWarning : <WarrantyPage listing={formData}/>} */}
                  {<WarrantyPage listing={formData} />}
                </Box>
              </TabPanel>
              {/* <TabPanel p={0} m={0}>
                <Box p={0} m={0} minHeight="70vh">
                  {notAvailableWarning ? notAvailableWarning : <CarfaxPage listing={formData}/>}
                </Box>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Container>
      </AppBody>
    </>
  );
}

export default EditListingVehicle;
