import {
    createContext,
    useState,
    useRef,
    useEffect
} from 'react';
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import {
    getAuth, onAuthStateChanged
} from 'firebase/auth';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
// import useAxiosFetch from '../hooks/useAxiosFetch';
// import { useAuthStatus } from '../hooks/useAuthStatus';
import { db } from '../constants';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    // const [error, setError] = useState(false)
    // const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState({});
    const [partnerData, setPartnerData] = useState({});
    const [loggedIn, setLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const isMounted = useRef(true);
    // const [posts, setPosts] = useState([])
    // const [search, setSearch] = useState('');
    // const [searchResults, setSearchResults] = useState([]);

    // const { loggedIn, checkingStatus } = useAuthStatus();

    // const { data, fetchError, isLoading } = useAxiosFetch('http://localhost:3500/posts');
    // const firestore = firebase.firestore();
    //   const docRef = firestore.doc('users/'+userData.uid);

    const auth = getAuth();

    useEffect(() => {

        if (!auth.currentUser) return;
        const unsubscribe = onSnapshot(doc(db, 'partnerMembers', auth.currentUser.uid), doc => {
            // console.log('user in onSnapshot', auth.currentUser)
            setUserData(doc.data());
        });
        return unsubscribe;
    }, [auth.currentUser]);

    useEffect(() => {
        if (isMounted) {


            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const docRef = doc(db, "partnerMembers", auth.currentUser.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const memberData = docSnap.data();
                        // console.log('user in onAuthStateChanged', user);
                        // console.log('user found user', auth.currentUser);
                        // console.log('data', docSnap.data())
                        const partnerRef = doc(db, "partners", memberData.partnerUid);
                        const partnerSnap = await getDoc(partnerRef);
                        setUserData(memberData)
                        setPartnerData(partnerSnap.data());
                        setLoggedIn(true);

                    } else {
                        // console.log("first login")
                        const partnerRef = doc(db, "partners", auth.currentUser.uid);
                        const partnerSnap = await getDoc(partnerRef);
                        setPartnerData(partnerSnap.data());
                        setLoggedIn(true);
                    }
                    // setCurrentUser(user);
                } else {
                    // console.log('there is no user');
                    setUserData(null);
                    setLoggedIn(false);
                }
                //   setIsLoading(false);
                setCheckingStatus(false);
            });
            return unsubscribe;
        }
        return () => {
            isMounted.current = false
        }
    }, [auth, isMounted]);

    return (
        <DataContext.Provider value={{
            loggedIn, checkingStatus, userData, partnerData
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;