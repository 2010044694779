import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {
  Box,
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  // Link,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const onChange = e => setEmail(e.target.value);

  const onSubmit = async e => {
    // console.log('onsubmit');
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
      toast({
        title: 'Success',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      setEmailSent(false);
      toast({
        title: 'Send Password Reset Error',
        description: 'Please try again or contact us for assistance',
        status: 'error',
        isClosable: true,
      });
    }
  };
  if (emailSent) {
    return (
      <>
        <Box>
          <Button pl={5} onClick={() => navigate('/login')} variant="ghost">
            <Text
              fontSize="xl"
              borderBottom="2px"
              borderBottomColor="primary.500"
            >
              EEZE
            </Text>
          </Button>
        </Box>
        <Box textAlign="center" py={10} px={6}>
          <CheckCircleIcon boxSize={'50px'} color={'primary.300'} />
          <Heading as="h2" size="xl" mt={6} mb={2}>
            Email was sent
          </Heading>
          <Text>
            If you have an email registered with us, you should receive a link
            to reset your password shortly. Please check your inbox and spam
            folder
          </Text>

          <Button
            onClick={() => navigate('/login')}
            colorScheme="primary"
            w="100%"
            maxWidth="400px"
            h="45"
            mt="20px"
          >
            GO BACK TO LOGIN PAGE
          </Button>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box>
        <Button pl={5} onClick={() => navigate('/login')} variant="ghost">
          <Text
            fontSize="xl"
            borderBottom="2px"
            borderBottomColor="primary.500"
          >
            EEZE
          </Text>
        </Button>
      </Box>
      <Flex align={'center'} justify={'center'}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={'white'}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Forgot your password?
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md' }}>
            Enter your email address and we will send a reset password link
          </Text>
          <form onSubmit={onSubmit}>
            <FormControl id="email">
              <Input
                placeholder="Email"
                _placeholder={{ color: 'gray.400' }}
                type="email"
                id="email"
                value={email}
                onChange={onChange}
              />
              <Stack spacing={6}>
                <Button type="submit" colorScheme="primary" mt="20px">
                  Request Reset
                </Button>
              </Stack>
            </FormControl>
          </form>
        </Stack>
      </Flex>
    </>
  );
}

export default ForgotPassword;
