import { useState, useEffect } from 'react';
// import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { db, LISTING } from '../constants';
import SpinnerPage from '../components/SpinnerPage';
import EditListingVehicle from './EditListingVehicle';
// import EditListingProduct from './EditListingProduct';

function EditListing() {
  const [listing, setListing] = useState(false);

  // const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();

  // Redirect if listing is not user's or if not vehicle
  // useEffect(() => {
  //   // console.log('listing.seller', listing.seller);
  //   // console.log('auth.currentUser.uid', auth.currentUser.uid);
  //   if (listing && listing.sellerUid !== auth.currentUser.uid) {
  //     toast({
  //       title: 'Cannot edit that listing',
  //       status: 'error',
  //       isClosable: true,
  //     });
  //     navigate('/');
  //   }
  // }, [auth, listing, navigate, toast]);

  // Fetch listing to edit
  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingUid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const thisListing = docSnap.data();

        setListing({...thisListing, uid: params.listingUid});
      } else {
        navigate('/');
        toast({
          title: 'Listing does not exist',
          status: 'error',
          isClosable: true,
        });
      }
    };

    fetchListing();
  }, [params, navigate, toast]);


  if (!listing) {
    return <SpinnerPage />;
  };

  // eezeID is autogenerated when listing created
  // if (!listing.eezeID) {
  //   return <SpinnerPage />;
  // };

  if (listing.type === LISTING.TYPE.VEHICLE) {
    return <EditListingVehicle listing={listing}/>
  };

  if (listing.type === LISTING.TYPE.PRODUCT) {
    return <EditListingVehicle listing={listing}/>
  };

}

export default EditListing;
