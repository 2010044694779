import { useEffect, useState, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  doc,
  getDoc,
  onSnapshot,
} from 'firebase/firestore'
import { db } from '../constants';

export const useAuthStatus = () => {
  const [userData, setUserData] = useState({})
  const [partnerData, setPartnerData] = useState({});
  const [loggedIn, setLoggedIn] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(true)
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted) {

      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // console.log('onAuthStateChanged')
          setLoggedIn(true)
          const docRef = doc(db, "partnerMembers", auth?.currentUser?.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            // console.log('user found in db', docSnap.data());
            // console.log('user found user', auth.currentUser);
            const memberData = docSnap.data();
            setUserData(memberData)
            const partnerRef = doc(db, "partners", memberData.partnerUid);
            const partnerSnap = await getDoc(partnerRef);
            if (partnerSnap.exists()) {

              setPartnerData(partnerSnap.data());
              setLoggedIn(true)
            }
          } else {
            // console.log("first login")
            const partnerRef = doc(db, "partners", auth.currentUser.uid);
            const partnerSnap = await getDoc(partnerRef);
            if (partnerSnap.exists()) {
              setPartnerData(partnerSnap.data());
              setLoggedIn(true)
            }
          }

        } else {
          // no user logged in
          setUserData(null)
          setLoggedIn(false)
        }
        setCheckingStatus(false)
      });

      if (loggedIn) {

        // const refreshPartner = async memberData => {
        //   console.log('refreshPartner function')
        //   const partnerRef = doc(db, "partners", memberData.partnerUid);
        //   const partnerSnap = await getDoc(partnerRef);
        //   setPartnerData(partnerSnap.data());
        // }
        onSnapshot(
          doc(db, 'partnerMembers', auth?.currentUser?.uid), (doc) => {
            // console.log('1111111111doc from  onSnapshot', doc.data())
            // setLoading(false)
            // if (!doc.exists()) return;
            const memberData = doc.data();
            if (memberData) {
              setUserData(memberData)
            }
            // console.log('ok')
            // refreshPartner(memberData)
            // setUser(doc)
          },
          err => {
            // setError(err)
          })

          if (userData?.partnerUid) {

            onSnapshot(
              // doc(db, 'partners', auth.currentUser?.uid), (doc) => {
                doc(db, 'partners', userData?.partnerUid), (doc) => {
                  // console.log('doc from  onSnapshot')
                  // console.log(doc.data())
                  // setLoading(false)
                  setPartnerData(doc.data())
                  
                  // setUser(doc)
                },
                err => {
                  // setError(err)
                })
              }

      }

    }

    return () => {
      isMounted.current = false
    }
  }, [isMounted, loggedIn])

  return { loggedIn, checkingStatus, userData, partnerData }
}

// Protected routes in v6
// https://stackoverflow.com/questions/65505665/protected-route-with-firebase

// Fix memory leak warning using useRef
// https://stackoverflow.com/questions/59780268/cleanup-memory-leaks-on-an-unmounted-component-in-react-hooks
