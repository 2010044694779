import { useState, useContext, useEffect } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  Center,
  Flex,
  Image,
  Skeleton,
  Divider,
  Input,
  InputLeftAddon,
  InputGroup,
  FormControl,
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Textarea,
  useToast,
  useClipboard,
} from '@chakra-ui/react';
import { CheckCircleIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { doc, onSnapshot } from 'firebase/firestore';
import { saveAs } from 'file-saver';

import DataContext from '../context/DataContext';
import { db } from '../constants';
import sendEmailLink from '../services/sendEmailLink';
import sendSMSLink from '../services/sendSMSLink';
import SpinnerPage from '../components/SpinnerPage';

const InviteModal = () => {
  const [email, setEmail] = useState('');
  const [emailMessageSent, setEmailMessageSent] = useState(false);
  const [cell, setCell] = useState('');
  const [partnerData, setPartnerData] = useState(null);
  const [SMSMessageSent, setSMSMessageSent] = useState(false);
  const [sending, setSending] = useState(false);
  const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const toast = useToast();
  const dataContext = useContext(DataContext);

  const { userData } = dataContext;
  const { partnerUid } = userData;

  const sender = partnerData?.businessName;
  // const slug = partnerData?.refSlug ? `?ref=${userData?.refSlug}` : '';

  const link = `https://eeze.co/${partnerData?.refSlug}`;
  // const tempLink = `https://app.eeze.co/signup/${partnerData?.refSlug}`;

  const message = `You've been invited by ${sender} to signup to EEZE at ${link}`;
  // const message = `You've been invited by ${sender} to signup to EEZE at ${partnerData?.approved ? link : tempLink}`;

  const qrUrl =
    partnerData?.refSlug &&
    `https://qr.rebrandly.com/v1/qrcode?shortUrl=https%3A%2F%2Feeze.co%2F${partnerData?.refSlug}&source=com.rebrandly.app&size=512`;

    useEffect(() => {
      const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
        setPartnerData(doc.data());
      });
      return unsubscribe;
    }, [partnerUid]);

  useEffect(() => {
    setValue(link);
    // setValue(partnerData?.approved ? link : tempLink);
  }, [setValue, link, partnerData]);

  const onChangeCell = e => {
    // do not allow more than 10 cell phone digits or letters
    if (e.target.value.length > 10) return;
    if (isNaN(e.target.value)) return;

    setCell(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const smsIsEnabled = cell.length === 10;
  const emailIsEnabled = email.includes('@');

  const onClickSendEmail = async () => {
    setSending(true);

    const response = await sendEmailLink(sender, email, message, 'invitation');

    if (response.success) {
      setEmailMessageSent(true);
      setSending(false);
    } else {
      setEmailMessageSent(false);
      setSending(false);
      toast({
        title: 'Could not send email',
        description:
          'Please try again later. ' + JSON.stringify(response?.error),
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onClickSendSMS = async () => {
    setSending(true);

    const response = await sendSMSLink(sender, cell, message);

    if (response.success) {
      setSMSMessageSent(true);
      setSending(false);
    } else {
      setSMSMessageSent(false);
      setSending(false);
      toast({
        title: 'Could not send SMS',
        description:
          'Please try again later. ' + JSON.stringify(response?.error),
        status: 'error',
        isClosable: true,
      });
    }
  };

  if (!partnerData) {
    return <SpinnerPage/>
  }
  return (
    <>
      <ModalHeader>Invite a customer to EEZE</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        {partnerData ? (
          <>
            {!partnerData?.approved && (
              <Alert status="info" my={2} variant="left-accent">
                {/* <AlertIcon /> */}
                The QR code or shortened referral link {link} will not work until your profile is approved
              </Alert>
            )}
            <Text>You can simply share your referral link</Text>
            <Textarea
              isReadOnly
              mr={2}
              size="sm"
              rows={1}
              value={value}
              // bg="gray.100"
              color="primary.500"
            />

            <Button colorScheme="primary" onClick={onCopy} w="full" mb={2}>
              {hasCopied ? 'Copied!' : 'Copy Referral Link'}
            </Button>

            <Flex mb={1} alignItems={'center'}>
              <Center flex={1}>
                <Image
                  w="30%"
                  fallback={
                    <Skeleton w="30%">
                      <Text>1</Text>
                      <Text>2</Text>
                      <Text>3</Text>
                      <Text>4</Text>
                    </Skeleton>
                  }
                  alt="qrCode"
                  src={qrUrl}
                />
              </Center>
            </Flex>
            <Button
              mb={5}
              rightIcon={<ExternalLinkIcon />}
              colorScheme="primary"
              w="full"
              onClick={() => saveAs(qrUrl, 'EEZE-QR.jpg')}
            >
              Download QR Code
            </Button>

            <Divider borderColor="primary.500" borderWidth={1} />

            <Text>Or send this message to a new customer</Text>
            <Card>
              <CardBody>
                <Text color="primary.900">{message}</Text>
              </CardBody>
            </Card>
            <br />
            {!emailMessageSent ? (
              <FormControl>
                <Input
                  placeholder="user@email.com"
                  type="email"
                  value={email}
                  onChange={onChangeEmail}
                />
                <Button
                  w="full"
                  mt={1}
                  colorScheme="primary"
                  disabled={!emailIsEnabled}
                  isLoading={sending}
                  onClick={onClickSendEmail}
                >
                  Send by email
                </Button>
              </FormControl>
            ) : (
              <Box textAlign="center">
                <CheckCircleIcon boxSize={'35px'} color={'blue.500'} />
                <Text mt={3}>Email sent to {email}</Text>
              </Box>
            )}

            {!SMSMessageSent ? (
              <FormControl mt={4}>
                <InputGroup>
                  <InputLeftAddon children="+1" />
                  <Input
                    placeholder="555 555 1212"
                    type="tel"
                    onChange={onChangeCell}
                    value={cell}
                  />
                </InputGroup>
                <Button
                  w="full"
                  mt={1}
                  colorScheme="primary"
                  disabled={!smsIsEnabled}
                  isLoading={sending}
                  onClick={onClickSendSMS}
                >
                  Send by SMS
                </Button>
              </FormControl>
            ) : (
              <Box textAlign="center">
                <CheckCircleIcon boxSize={'35px'} color={'blue.500'} mt={5} />
                <Text mt={3}>SMS sent to {cell}</Text>
              </Box>
            )}
          </>
        ) : (
          <>
            <Text>You must login / signup to send this invitation</Text>
          </>
        )}
      </ModalBody>
    </>
  );
};

export default InviteModal;
