import { Box, Heading, Text, Button, Link } from '@chakra-ui/react';
// import AppBody from '../components/AppBody';

export default function NotFound() {
  return (
    <>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          // size="2xl"
          bgGradient="linear(to-r, pink.400, pink.600)"
          backgroundClip="text"
        >
          Page Not Found
        </Heading>
        <Text color={'gray.700'} mb={6} mt={10}>
          The page you're looking for does not seem to exist
        </Text>

        <Link href="/">
          <Button
            bgGradient="linear(to-r, pink.400, pink.500, pink.600)"
            color="white"
            variant="solid"
          >
            Go to Home
          </Button>
        </Link>
      </Box>
    </>
  );
}
