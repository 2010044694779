import { useState, useContext } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  useToast,
  Badge,
  Text,
  IconButton,
  Button,
  Center,
  Checkbox,
  Box,
  Stack,
  HStack,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Modal,
  Table,
  Tr,
  Td,
  Tbody,
  Th,
  Thead,
  TableContainer,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon, CloseIcon } from '@chakra-ui/icons';
import { FaCheckCircle, FaCheckSquare, FaRegSquare } from 'react-icons/fa';

import { db, PRICING_OPTIONS, LINK, masterSafetyPromotionEnabled } from '../constants';
import SpinnerPage from '../components/SpinnerPage';
import DataContext from '../context/DataContext';
import currencyFormat from '../services/currencyFormat';
import numberFormat from '../services/numberFormat';
import sendNoticeToOwner from '../services/sendNoticeToOwner';

function WarrantyPage(props) {
  const { t } = useTranslation('global');
  const [listing, setListing] = useState(props.listing);
  const [promoSelected, setPromoSelected] = useState(false);
  const [addonCode, setAddonCode] = useState(
    props.listing?.warrantyAddonCode || null
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenInfo,
    onOpen: onOpenInfo,
    onClose: onCloseInfo,
  } = useDisclosure();
  // const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  // const params = useParams();
  const dataContext = useContext(DataContext);
  const { userData, partnerData } = dataContext;
  const province = listing?.province;

  if (!listing) {
    return <SpinnerPage />;
  }
  // console.log('listing', props.listing)
  const sendPaymentLink = () => {
    // console.log('warrantyName',PRICING_OPTIONS?.addons[addonCode].warrantyName)
    // console.log('term',PRICING_OPTIONS?.addons[addonCode].term)
    // save plan to listing
    const listingRef = doc(db, 'listings', listing.uid);
    updateDoc(listingRef, {
      warrantyAddonCode: PRICING_OPTIONS?.addons[addonCode].code,
      // warrantyActive: false,
    });
    // send notification to owner/seller
    sendNoticeToOwner({
      businessName: userData.businessName,
      listingName: listing.title,
      sellerCell: listing.sellerCell,
      sellerEmail: listing.sellerEmail.replace('+', '%2b'),
      sellerName: listing.sellerName,
    });

    toast({
      title: 'Notification sent to owner to conclude the transaction',
      status: 'success',
      isClosable: true,
    });
    navigate(-1);
  };
  const renderPlanInfo = () => {
    return (
      <>
        <Center>
          <Text
            mt={3}
            textAlign="center"
            fontSize="2xl"
            lineHeight={1.2}
            fontWeight="bold"
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            {t('powertrain')}
          </Text>
        </Center>
        <Center>
          <Text fontSize="lg">vs</Text>
        </Center>
        <Center align="center" mb={3}>
          <Text
            //  mt={3}
            textAlign="center"
            fontSize="2xl"
            lineHeight={1.2}
            fontWeight="bold"
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            {t('comprehensive')}
          </Text>
        </Center>
        <TableContainer>
          <Table
            variant="simple"
            // w='100vw'
            layout="fixed"
          >
            <Thead>
              <Tr>
                <Th p={1} borderWidth={0} borderColor="black"></Th>
                <Th
                  p={1}
                  w="10%"
                  borderWidth={1}
                  borderColor="black"
                  textAlign="center"
                >
                  {t('powertrainShort')}
                </Th>
                <Th
                  p={1}
                  w="10%"
                  borderWidth={1}
                  borderColor="black"
                  textAlign="center"
                >
                  {t('comprehensiveShort')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature1')}
                </Td>
                <Td p={1} borderWidth={1} borderColor="black">
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature2')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature1')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature3')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>
              <Tr>
                <Td p={1} pl={3} borderColor="black" borderBottomWidth={1}>
                  <Text as="i" fontWeight="bold">
                    {t('coverage')}
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature4')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature5')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature6')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature7')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature8')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('sharedCoverageFeature9')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature10')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature2')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature3')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature4')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature5')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature6')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature7')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature8')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>

              <Tr>
                <Td
                  overflow="scroll"
                  p={1}
                  pl={3}
                  borderWidth={1}
                  borderColor="black"
                >
                  {t('comprehensiveOnlyFeature9')}
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  -
                </Td>
                <Td
                  p={1}
                  textAlign="center"
                  borderWidth={1}
                  borderColor="black"
                >
                  <Center>
                    <FaCheckCircle />
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Text my={2} mt={5} textAlign="center">
          <a
            href={LINK.warrantyPlansUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('moreInfo')}
            <ExternalLinkIcon ml="5px" />
          </a>
        </Text>
      </>
    );
  };
  const priceBuyButtons = () => {
    return (
      <>
        {addonCode && addonCode !== 'carfax' ? (
          <>
            <HStack align={'center'} justify={'center'}>
              <Text fontSize={'xl'}>$</Text>
              <Text fontSize={'3xl'} fontWeight={600}>
                {numberFormat(
                  PRICING_OPTIONS.addons[addonCode][province]?.priceBeforeTax
                )}
              </Text>
            </HStack>
            <Text align="center" fontSize="lg">
              +{' '}
              {currencyFormat(
                PRICING_OPTIONS.addons[addonCode][province]?.taxes?.gst
              )}
              {PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst === 0 ? (
                ' HST'
              ) : (
                <>
                  {' GST + ' +
                    currencyFormat(
                      PRICING_OPTIONS.addons[addonCode][province]?.taxes?.qst
                    ) +
                    ' QST'}
                </>
              )}
            </Text>
            <HStack align={'center'} justify={'center'}>
              <Text fontSize={'xl'}>$</Text>
              <Text fontSize={'2xl'}>
                {numberFormat(
                  PRICING_OPTIONS.addons[addonCode][province]?.priceIncludingTax
                )}
              </Text>
            </HStack>
            <Text textAlign="center">Including tax</Text>
            <Button
              isDisabled={addonCode ? false : true}
              w="full"
              colorScheme="primary"
              mt={4}
              size="lg"
              onClick={sendPaymentLink}
            >
              Send Payment Link to Customer
            </Button>

            <br />
            <br />
            <br />
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </>
    );
  };
  const planSelector = filteredArray => {
    // console.log('addoncode', addonCode)
    // console.log('filteredArray', filteredArray)
    return (
      <>
        {/* {partnerData.safetyPromotionEnabled && renderSafetyPromotion()} */}

        <Box minWidth="330px" pb={2} px={2}>
          <Stack direction="row" justify="space-between" my={3}>
            <IconButton
              variant="ghost"
              size={'xs'}
              color="grey"
              // icon={<CloseIcon />}
            />
            <Text fontSize="sm">SELECT NEW PLAN FOR YOUR CUSTOMER</Text>
            <IconButton
              variant="ghost"
              size={'xs'}
              color="grey"
              icon={addonCode || promoSelected ? <CloseIcon /> : null}
              onClick={() => {
                setAddonCode();
                setPromoSelected(false);
              }}
            />
          </Stack>

          {masterSafetyPromotionEnabled && (
            <>
              <HStack
                mb={2}
                onClick={() => {
                  setPromoSelected(!promoSelected);
                  setAddonCode();
                }}
                borderColor={promoSelected ? 'primary.500' : 'gray.400'}
                borderRadius={'lg'}
                p={1}
                borderWidth={promoSelected ? 2 : 1}
              >
                {/* <Checkbox colorScheme="primary" isChecked={promoSelected} /> */}
                {/* checkbox not selecting here */}
                {promoSelected ? (
                <FaCheckSquare color="purple" />
              ) : (
                <FaRegSquare color="lightgray" />
              )}
                <Stack direction="row" justify="space-between" w="100%">
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">
                      Powertrain
                      {/* {french ? plan.nameFr : plan.name} */}
                    </Text>
                    <Text fontWeight="bold" fontSize="md">
                      FREE with Every Safety Inspection
                    </Text>
                  </Box>
                  <Box
                    textAlign="center"
                    // w='35%'
                  >
                    <Text fontWeight="bold" fontSize="md">
                      90-Day
                    </Text>
                    <Text fontWeight="bold" fontSize="xl">
                      $0
                    </Text>
                    <Badge
                      rounded="full"
                      px="2"
                      fontSize="xs"
                      colorScheme="red"
                      // bgColor='red'
                      // color='white'
                    >
                      Special Offer
                    </Badge>
                  </Box>
                </Stack>
              </HStack>
            </>
          )}





          <Stack direction="column">
            {filteredArray.map(plan => (
              <HStack
                key={plan.code}
                // onClick={e => setAddonCode(plan.code)}
                onClick={e => {
                  setAddonCode(plan.code);
                  setPromoSelected(false);
                }}
                borderColor={
                  plan.code === addonCode ? 'primary.500' : 'gray.400'
                }
                borderRadius={'lg'}
                p={1}
                borderWidth={plan.code === addonCode ? 2 : 1}
              >
                <Checkbox
                  colorScheme="primary"
                  isChecked={plan.code === addonCode}
                />
                <Stack direction="row" justify="space-between" w="100%">
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">
                      {plan.name}
                    </Text>
                    <Text fontSize="xs">{plan.feature1}</Text>
                    <Text fontSize="xs">{plan.feature2}</Text>
                    {/* <Text fontSize="xs">{plan.feature3}</Text> */}
                  </Box>
                  <Box textAlign="center">
                    <Text fontWeight="bold" fontSize="md">
                      {plan.term}
                    </Text>
                    <Text fontWeight="bold" fontSize="lg">
                      {plan.price1}
                    </Text>
                    <Text>{plan.price2}</Text>
                  </Box>
                </Stack>
              </HStack>
            ))}
          </Stack>

          {promoSelected && (
            <Button
              mt={2}
              colorScheme="primary"
              size="lg"
              w="full"
              onClick={onClickActivatePromo}
            >
              Select Warranty and Upload Safety
            </Button>
          )}


        </Box>

        {/* <Center>
          <Button
            variant="link"
            colorScheme="primary"
            leftIcon={<InfoOutlineIcon />}
            onClick={onOpenInfo}
            mb={3}
          >
            View Plan Details
          </Button>
        </Center> */}
      </>
    );
  };
  const warrantyBuyOptions = () => {
    // get warranties from PRICING_OPTIONS.addons
    const allAddonsArray = Object.values(PRICING_OPTIONS.addons);
    // get only WARRANTY
    // upgrade:true if the vehicle already has an EEZE plan
    // console.log(allAddonsArray);
    const filteredArray = allAddonsArray.filter(
      obj => obj.type === 'WARRANTY' && obj?.upgrade !== true
    );
    // console.log(filteredArray);
    return (
      <>
        {planSelector(filteredArray)}

        {priceBuyButtons()}
      </>
    );
  };
  // const warrantyUpgradeOptions = () => {
  //   // get warranties from PRICING_OPTIONS.addons
  //   const allAddonsArray = Object.values(PRICING_OPTIONS.addons);
  //   // get only WARRANTY
  //   // upgrade:true if the vehicle already has an EEZE plan
  //   // console.log(allAddonsArray);
  //   const filteredArray = allAddonsArray.filter(
  //     obj => obj.type === 'WARRANTY' && obj?.upgrade === true
  //   );

  //   // console.log(filteredArray);

  //   return (
  //     <>
  //       {planSelector(filteredArray)}

  //       {priceBuyButtons()}
  //     </>
  //   );
  // };
  const warrantyNotActive = () => {
    return (
      <>
        <Text textAlign="center" color="blue.500" mb={5}>
          The selected warranty is being processed
        </Text>
      </>
    );
  };
  const onClickActivatePromo = async () => {
    // update listing
    const listingRef = doc(db, 'listings', listing.uid);
    await updateDoc(listingRef, {
      warrantyName: 'Powertrain 90-Days',
      warrantyActive: false,
      warrantySafetyPromo: true,
    });

    // redirect to edit page to upload safety
    navigate('/listing/' + listing.uid);
  };
  // const renderSafetyPromotion = () => {
  //   return (
  //     <>
  //       <Alert
  //         status="info"
  //         variant="left-accent"
  //         flexDirection="column"
  //         alignItems="center"
  //         justifyContent="center"
  //         textAlign="center"
  //       >
  //         <AlertIcon mr={0} />
  //         <AlertTitle mt={1} mb={1} fontSize="MD">
  //           LIMITED TIME OFFER
  //         </AlertTitle>
  //         <AlertDescription maxWidth="lg">
  //           Your customers will get a FREE 90-day Powertrain warranty with every
  //           safety inspection uploaded to EEZE
  //           <br />
  //           <br />
  //           <Box>
  //             <Button
  //               w="full"
  //               colorScheme="primary"
  //               size="lg"
  //               onClick={onClickActivatePromo}
  //             >
  //               Select and upload safety
  //             </Button>
  //           </Box>
  //         </AlertDescription>
  //       </Alert>
  //     </>
  //   );
  // };
  const renderWarrantyScreen = () => {
    // if no warranty
    if (!listing?.warrantyName || listing?.warrantyName === '') {
      return (
        <>
          {/* <Center>
            <PlansImage width={'30%'} />
          </Center> */}

          <Text align="center" mb={2}>
                  {/* {t('driveWorryFreeChoosePlan')} */}
            Choose basic Powertrain or full Comprehensive plan

                  <Text
                    as="span"
                    p={2}
                    fontWeight="bold"
                    onClick={onOpenInfo}
                    color="primary.500"
                  >
                    {' '}
                    {t('viewEEZEPlanDetails')}
                  </Text>
                </Text>


          {warrantyBuyOptions()}
        </>
      );
    }
    // some warranty exists

    // only when 90 day selected, but not activated are upgrade options available
    // if base 90 days warranty
    if (listing?.warrantyName.includes('90')) {
      if (!listing?.warrantyActive) {
        // we are working + upgrade options
        return (
          <>
            <Center>
              <Text fontSize="sm" as="i" textAlign="center">
                Existing Warranty
              </Text>
            </Center>
            <Center mb={5}>
              <Text
                textAlign="center"
                fontSize="2xl"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {listing?.warrantyName}
              </Text>
            </Center>
            {warrantyNotActive()}
            {/* {warrantyUpgradeOptions()} */}
          </>
        );
      } else {
        // warranty active, show buy options
        return (
          <>
            <Center>
              <Text fontSize="sm" as="i" textAlign="center">
                Active Warranty
              </Text>
            </Center>
            <Center mb={5}>
              <Text
                textAlign="center"
                fontSize="2xl"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {listing?.warrantyName}
              </Text>
            </Center>
            {/* {warrantyBuyOptions()} */}
          </>
        );
      }
    } else {
      // warranty other than 90 base
      if (!listing?.warrantyActive) {
        // we are working
        return (
          <>
            <Center>
              <Text fontSize="sm" as="i" textAlign="center">
                Selected Warranty
              </Text>
            </Center>
            <Center mb={5}>
              <Text
                textAlign="center"
                fontSize="2xl"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {listing?.warrantyName}
              </Text>
            </Center>
            {warrantyNotActive()}
          </>
        );
      } else {
        // warranty active, show buy options
        return (
          <>
            <Center>
              <Text fontSize="sm" as="i" textAlign="center">
                Active Warranty
              </Text>
            </Center>
            <Center mb={5}>
              <Text
                textAlign="center"
                fontSize="2xl"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {listing?.warrantyName}
              </Text>
            </Center>
            {/* {warrantyBuyOptions()} */}
          </>
        );
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <BuyAddonModal
            addon={PRICING_OPTIONS.addons[addonCode]}
            listingUid={listing.uid}
            province={province}
          /> */}
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={() => {
                onClose();
              }}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenInfo} onClose={onCloseInfo}>
        <ModalOverlay />
        <ModalContent>
          {renderPlanInfo()}
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={() => {
                onCloseInfo();
                // setAddonCode();
              }}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*   a buyer canot see the warranties tab until status completed */}
      {/* {listing.buyerUid === userData.uid &&
      listing.status !== LISTING.STATUS.COMPLETED ? (
        <Text my={10} textAlign="center">
          Only the seller can activate warranty plans before the transaction is
          completed
        </Text>
      ) : ( */}
      <>{renderWarrantyScreen()}</>
      {/* )} */}
    </>
  );
}

export default WarrantyPage;
