import {
    getAuth,
} from 'firebase/auth';
import {
    SEND_NOTICE_TO_OWNER,
} from '../constants';

const sendNoticeToOwner = async ({
    businessName,
    listingName,
    sellerCell,
    sellerEmail,
    sellerName}) => {

    const url = `${SEND_NOTICE_TO_OWNER}?listingName=${listingName}&sellerEmail=${sellerEmail}&sellerName=${sellerName}&sellerCell=${sellerCell}&businessName=${businessName}`;

    const auth = getAuth();

    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            url, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',

            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data from sendEmailLink', data);
        return data
    }

    catch (error) {
        // console.log(error)
        return error;
    }
}

export default sendNoticeToOwner