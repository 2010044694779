const htmlContent = (partnerData) => {

    return `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="font-family: Arial, sans-serif; line-height: 1.6; margin: 20px;">
    <p>This AFFILIATE PROGRAM AGREEMENT is entered in the City of ${partnerData?.address?.city} in the Province of ${partnerData?.address?.province}  this {{effectiveDate}} (the "Effective Date").</p>
<br/>
<div style="text-align: center;">

<h2 style="color: #333;">BETWEEN</h2>
<br/>
<p><strong>EEZE INC.</strong></p>
<p>6500 Trans-Canada Hwy, Suite 400 Pointe Claire, Quebec H9R 0A5</p>
<p>(866) 551-1520 / info@eeze.co</p>
<p>("EEZE")</p>
<br/>

<h2 style="color: #333;">AND</h2>
<br/>
<p><strong>${partnerData?.businessLegalName || partnerData?.businessName}</strong></p>
<p>${partnerData?.address?.civic} ${partnerData?.address?.street}, ${partnerData?.address?.city}, ${partnerData?.address?.province} ${partnerData?.address?.postal}</p>
<p>${partnerData?.businessPhone} / ${partnerData?.primaryEmail}</p>
<p>("Affiliate")</p>
<br/>
</div>

    <h2 style="color: #333;">RECITALS:</h2>
    <p><strong>A.</strong> EEZE is in the business of providing verification, escrow service, aftermarket products, and warranties for private vehicle transactions to members of the public ("Customers") through its proprietary online platform ("EEZE Platform").</p>
    <p><strong>B.</strong> The Affiliate is an individual or business who will assist EEZE in obtaining new Customers.</p>
    <p><strong>D.</strong> The Parties wish to specify the terms of a program where EEZE will reward the Affiliate for new Customers directly attributed to the Affiliate.</p>

    <h2 style="color: #333;">NOW THEREFORE</h2>
    <p>In consideration of the mutual covenants and agreements set out in this Agreement, the Parties agree as follows:</p>
    
    <ol>
        <li>EEZE will provide the Affiliate with access to a EEZE Web Portal and custom onboarding Widgets where potential Customers can begin the process with EEZE. This Portal will track Customers directly attributed to the Affiliate and will display the Customer's progress through their onboarding journey.</li>
        <li>The Customer onboarding journey consists of sequential Achievement Milestones displayed as STATUS in the Portal.</li>
        <li>EEZE will pay the Affiliate according to the following table of Affiliate Program Fees:
            <table style="width: 100%; border-collapse: collapse; margin: 20px 0;">
               
                <tbody>
                  
                </tbody>
                <thead>
                    <tr>
                        <th style="border: 1px solid #333; padding: 10px; background-color: #f2f2f2;">Warranty</th>
                        <th style="border: 1px solid #333; padding: 10px; background-color: #f2f2f2;">Fixed Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="border: 1px solid #333; padding: 10px;">Powertrain 1-year</td>
                        <td style="border: 1px solid #333; padding: 10px;">$${partnerData?.warrantyPowertrain1Year || 50}.00</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #333; padding: 10px;">Comprehensive 1-year</td>
                        <td style="border: 1px solid #333; padding: 10px;">$${partnerData?.warrantyComprehensive1Year || 100}.00</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #333; padding: 10px;">Comprehensive 2-year</td>
                        <td style="border: 1px solid #333; padding: 10px;">$${partnerData?.warrantyComprehensive2Year || 150}.00</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #333; padding: 10px;">Comprehensive 3-year</td>
                        <td style="border: 1px solid #333; padding: 10px;">$${partnerData?.warrantyComprehensive3Year || 200}.00</td>
                    </tr>
                </tbody>
            </table>
            <p>These fees will be paid to the Affiliate on a monthly basis.</p>
        </li>
        <li>The monthly participation fee paid by the Affiliate to EEZE is $0.</li>
        <li>The initial term of the Agreement will begin on the date of this Agreement and continue for one (1) year and the Agreement will automatically renew for successive terms of one (1) year each.</li>
        <li>Either Party may immediately terminate this Agreement at any time by providing written notice to the other Party of such termination.</li>
        <li>EEZE retains all ownership and copyright interest in and to any intellectual property technology and processes developed by EEZE in connection with the EEZE Platform or the EEZE Portal or EEZE Affiliate Portal which are used by the Affiliate in connection with this Agreement (collectively the "EEZE IP"). EEZE hereby grants the Affiliate a non-exclusive, non-assignable license to use the EEZE IP for the limited purposes of promoting EEZE to new Customers. The license shall terminate automatically upon the termination of this Agreement.</li>
        <li>EEZE may, in printed materials, online marketing channels, on its website or applications, represent that the Affiliate is an "affiliate" or "marketing affiliate" and use its name and logo. All intellectual property rights in such marketing materials and otherwise arising out of the terms and conditions of this Agreement shall be the exclusive property of EEZE.</li>
        <li>The relationship between the Parties is not a partnership, and each Party is an independent contractor.</li>
        <li>The Affiliate has no authority to act on behalf of or otherwise bind EEZE.</li>
        <li>Except as expressly provided herein, EEZE has no duties or responsibilities, implied fiduciary or otherwise, to the Affiliate.</li>
        <li>The Affiliate agrees that this Agreement, as well as any related documents, will be drawn up in the English language only.</li>
        <li>This Agreement shall be construed and governed in accordance with the laws of the province of Ontario and the federal laws of Canada applicable therein.</li>
        <li>EEZE may at any time notify the Affiliate in writing of any changes to this Agreement which are required by EEZE. The Affiliate will be deemed to accept the change to the Agreement as set out in the notice.</li>
    </ol>
<br/>
    <p>IN WITNESS WHEREOF the parties have executed this Agreement as of the date set forth above.</p>
<br/>
    <p>The Affiliate hereby acknowledges that the Affiliate has read, understood, and agrees to the above conditions.</p>
</body>
</html>
`

}

export default htmlContent