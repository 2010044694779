import {
  Box,
  Heading,
  Text,
  Button,
  Link,
  Center,
  Image,
} from '@chakra-ui/react';
import AppBody from '../components/AppBody';

import logoImage from '../assets/shield256.png';

export default function ContractSigned() {
  return (
    <AppBody>
      <Box textAlign="center" py={10} px={6}>
        <Center>
          <Image src={logoImage} alt="Logo" boxSize="60px" objectFit="cover" />
        </Center>
        <Text mt={20} fontSize="lg">
          Thank you!
        </Text>
        <Heading
          display="inline-block"
          as="h3"
          my={10}
        >
          EEZE Affiliate Program Agreement Executed
        </Heading>
        <Text>You will receive the executed copy by email</Text>
        <Link href="/">
          <Button
            bgGradient="linear(to-r, pink.400, pink.500, pink.600)"
            color="white"
            variant="solid"
            mt={10}
          >
            Go to EEZE Partner Homepage
          </Button>
        </Link>
      </Box>
    </AppBody>
  );
}
